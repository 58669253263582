import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "./assets/css/main.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ThemeProvider } from "@material-ui/core";
import mainTheme from "./theme";

import WelcomeScreen from "./views/Welcome";
/* import PlayScreen from "./views/Play";
import AdsScreen from "./views/Ads"; */

const hist = createBrowserHistory();

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <Router history={hist}>
      <Switch>
        {/* <Route path="/play" component={PlayScreen} />
        <Route path="/ads" component={AdsScreen} /> */}
        <Route path="/" component={WelcomeScreen} />
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an a@types/historynalytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
