import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: 0,
      height: "auto",
      margin: 0,
      display: "inherit",
      padding: 10,
      position: "relative",
      minHeight: "100vh",
      alignItems: "center",
      background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      justifyContent: "center",
    },
    logo: {
      width: "50vw",
      maxWidth: 120,
    },
    paper: {
      padding: 20,
      marginTop: 10,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);
export default useStyles;
