import React, { useRef } from "react";

import useStyles from "./styles";

import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

/*import * as yup from "yup";
import { useFormik } from "formik";
import InputMask from "react-input-mask"; */

import Header from "components/Header";
import CustomAlert from "components/CustomAlert";

import logoMercado from "assets/img/mercado.png";

//import { useHistory } from "react-router";
//import axios from "config/axios";

/* const SubmitSchema = yup.object().shape({
  promoCod: yup
    .string()
    .test("len", "O código deve ter 6 caracteres", (val) => {
      val = val?.replace(/_/g, "");
      return val?.length === 6;
    })
    .required("O código da promoção é obrigatório"),
  email: yup
    .string()
    .email("Entre com um email válido")
    .required("O email é obrigatório"),
}); */

const Welcome: React.FC = () => {
  //const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const alertRef = useRef<any>();
  //const history = useHistory();

  /* const checkAndSubmit = async (values: {
    promoCod: string;
    email: string;
  }) => {
    setLoading((oldState) => !oldState);
    axios
      .post("/promo/mmcr/check/and/submit", {
        promoCod: values.promoCod,
        email: values.email,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        history.push("/play", { promoId: data.promoId });
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error.response?.data?.status) {
          case 1:
            alertRef?.current.alterAlert(
              "Código promocional não encontrado",
              "warning"
            );
            break;
          case 2:
            alertRef?.current.alterAlert(
              "Código promocional já utilizado",
              "warning"
            );
            break;
          case 3:
            alertRef?.current.alterAlert(
              "Você já participou da promoção",
              "warning"
            );
            break;
          default:
            alertRef?.current.alterAlert(
              "Problema ao verificar código",
              "error"
            );
            break;
        }
      });
  }; */

/*   const formikForm = useFormik({
    initialValues: {
      promoCod: "",
      email: "",
    },
    validationSchema: SubmitSchema,
    onSubmit: (values) => {
      checkAndSubmit(values);
    },
  });
 */
  return (
    <>
      <CustomAlert ref={alertRef} />
      <div className={classes.container}>
        <Header />
        <Paper className={classes.paper}>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography align="justify" style={{ padding: 5 }}>
                <b>
                 Promoção encerrada. Fique atento, mais novidades estão chegando. 
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <img
                alt="Logo Mercado Municipal"
                src={logoMercado}
                className={classes.logo}
              />
            </Grid>
            {/*<Grid item>
              <Typography align="center">
                A cada R$ 100,00 em compras você ganha um vale para participar
                do sorteio.
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center">
                Retire o seu nas lojas <b>Tachibana</b>, <b>Uniformiza</b> e{" "}
                <b>Be Home</b>
              </Typography>
            </Grid>
             <Grid item>
              <InputMask
                mask="******"
                type="text"
                name="promoCod"
                onChange={(e) =>
                  formikForm.setFieldValue(
                    "promoCod",
                    e.target.value.toUpperCase()
                  )
                }
                value={formikForm.values.promoCod}
              >
                {() => (
                  <TextField
                    fullWidth
                    label="Vale sorteio"
                    error={
                      formikForm.touched.promoCod &&
                      Boolean(formikForm.errors.promoCod)
                    }
                    helperText={
                      formikForm.touched.promoCod && formikForm.errors.promoCod
                    }
                    name="promoCod"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Seu email"
                type="email"
                error={
                  formikForm.touched.email && Boolean(formikForm.errors.email)
                }
                onChange={formikForm.handleChange}
                value={formikForm.values.email}
                helperText={formikForm.touched.email && formikForm.errors.email}
                name="email"
              />
            </Grid> 
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    formikForm.handleSubmit();
                  }}
                >
                  PARTICIPAR
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>*/}
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default Welcome;
