import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      width: "40vw",
      padding: 5,
      maxWidth: 150,
    },
    paper: {
      padding: 5,
    },
  })
);
export default useStyles;
